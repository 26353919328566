@font-face {
  font-family: "Averta Thin";
  src : url("../fonts/AvertaStd-Thin.ttf");
}

@font-face {
  font-family: "Averta ExtraBold";
  src : url("../fonts/AvertaStd-ExtraBold.ttf");
}

@font-face {
  font-family: "DM Sans";
  src : url("../fonts/DMSans.ttf");
}
