@import "app/@core/@scss/styles";
@import "variables";
@import "helpers";

/* para que no se vea el recaptcha */
.grecaptcha-badge { visibility: hidden; }

/*
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/

nb-menu .menu-item{
  border: none !important;
}

.menu-title{
  font-style: normal;
  font-weight: $clubintel-weight-medium;
  font-size: $clubintel-size-small;
}


.condiciones img{
  object-fit: cover;
  max-width: 100% !important;
}

nb-toggle.status-danger .toggle {
  background-color: var(--toggle-danger-background-color) !important;
  border-color: var(--toggle-danger-border-color) !important;
}

nb-toggle.status-danger .toggle-switcher {
  background-color: var(--toggle-danger-checked-switcher-background-color);
}


nb-toggle.status-success .toggle {
  background-color: var(--toggle-success-background-color) !important;
  border-color: var(--toggle-success-border-color) !important;
}

nb-toggle.status-success .toggle-switcher {
  background-color: var(--toggle-success-checked-switcher-background-color);
}


nb-sidebar-footer .button{
  border: none !important;
}

.cursor-pointer{
  z-index: 9999;
  position: relative;
}

