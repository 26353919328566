
@import '~animate.css'; // https://animate.style/


.animation-rotate90 {
  transform: rotate(90deg);
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease-in;
}
.animation-rotate-90 {
  transform: rotate(-90deg);
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease-in;
}
.animation-rotate180 {
  transform: rotate(180deg);
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease-in;
}
.animation-rotate360 {
  transform: rotate(360deg);
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease-in;
}

.animation-flip {
  animation: flipInY;
  animation-duration: 1s;
}

.animation-flash {
  animation: flash;
  animation-duration: 1s;
}
.animation-fade-out-left {
  animation: fadeOutLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animation-fade-out {
  animation: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


.animation-roll-out {
  animation: rollOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animation-zoom-in {
  animation: zoomIn;
  animation-duration: 1s;
}

.animation-zoom-out {
  animation: zoomOut;
  animation-duration: 1s;
}

.animation-pulse {
  animation: pulse;
  animation-duration: 0.250s;
}

.animation-heartbeat {
  animation: heartBeat;
  animation-duration: 1s;
}

.animation-shake {
  animation: headShake;
  animation-duration: 1s;
}

.animation-shake-x {
  animation: shakeX;
  animation-duration: 1s;
}


.animation-shake-y {
  animation: shakeY;
  animation-duration: 1s;
}

.animation-entrance {
  animation: bounceIn;
  animation-duration: 1s;
}

.animation-entrance-right {
  animation: bounceInRight;
  animation-duration: 1s;
}

.animation-entrance-left {
  animation: bounceInLeft;
  animation-duration: 1s;
}


.animation-entrance-up {
  animation: bounceInUp;
  animation-duration: 1s;
}


.animation-entrance-down {
  animation: bounceInDown;
  animation-duration: 1s;
}
