@import "app/@core/@scss/themes";

$clubintel-primary-color: nb-theme(color-primary-500);

// Escala de tamaños tipográficos
$clubintel-size-xxxx-large: 1.8rem;
$clubintel-size-xxx-large: 1.6rem;
$clubintel-size-xx-large: 1.42rem;
$clubintel-size-x-large: 1.25rem;
$clubintel-size-large: 1.125rem;
$clubintel-size-medium: 1rem;
$clubintel-size-small: 0.875rem;
$clubintel-size-xx-small: 0.75rem;
$clubintel-size-xxx-small: 0.688rem;
$clubintel-size-xxxx-small: 0.625rem;
$clubintel-size-xxxxx-small: 0.5rem;

// Escala de grosor tipográfico
$clubintel-weight-bold: 700;
$clubintel-weight-semibold: 600;
$clubintel-weight-medium: 500;
$clubintel-weight-regular: 400;
$clubintel-weight-light: 300;

