@use 'themes' as *;
@use '../../../../node_modules/@nebular/theme/styles/globals' as *;

@import "material";


@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */


@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";


/* Importing Bootstrap SCSS file. */
@import '../../../../node_modules/bootstrap/scss/bootstrap';

@import "utils";

@import "animations";


