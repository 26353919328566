
@import 'macros';


body {
  background-color: nb-theme(layout-background-color);
}


html, body, nb-layout {
  height: 100%;

  .scrollable-container {
    height: 100% !important;
  }

}

.center-absolute {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.dialog-wrapper {
  max-height: 90vh;
  max-width: 80vw;
  overflow: auto;
  margin: 0;
}

.theme-border {
  border: nb-theme(card-border-width) nb-theme(card-border-style) nb-theme(card-border-color);
  border-radius: nb-theme(card-border-radius);
}

%extend-selected {
  background-color: nb-theme(color-basic-transparent-200);
  // color: nb-theme(menu-item-active-text-color);
}

%extend-selected-border {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    @include nb-ltr(left, 0);
    @include nb-rtl(right, 0);
    top: 0;
    height: 100%;
    width: 4px;
    background: nb-theme(sidebar-menu-item-highlight-color);
  }
}


nb-menu.no-selectable .menu-item a.active {
  color: unset;
}


nb-menu.selectable .menu-item a.active {
  // background-color: nb-theme(menu-item-active-background-color);
  // color: nb-theme(menu-item-active-text-color);
  @extend %extend-selected;
  @extend %extend-selected-border;
}

// Definición de las clases theme-info, theme-success, etc
@each $status in nb-get-statuses() {

  .theme-#{$status} {
    color: nb-theme(color-#{$status}-default) !important;
  }

  .theme-background-#{$status} {
    background-color: nb-theme(color-#{$status}-default) !important;
  }

  .theme-border-#{$status} {
    border-color: nb-theme(color-#{$status}-default) !important;
  }

  .table-#{$status} {
    table-layout: auto;
    width: 100%;

    td {
      color: nb-theme(text-hint-color);
      padding: 0.5rem 1rem;
    }

    th {
      background-color: nb-theme(card-header-#{$status}-background-color);
      color: nb-theme(card-header-#{$status}-text-color);
      padding: 0.6rem 1rem;
    }

    tr:hover {
      background-color: nb-theme(card-divider-color);
    }

    tr.selected,  tr.selected td{
      @extend %extend-selected;
    }

    /* Change these properties */
    border-radius: nb-theme(card-border-radius);

    /* Don't change these properties */
    border-spacing: 0;
    border-collapse: separate;
    border: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
    overflow: hidden;

    /* Apply a border to the right of all but the last column */
    th:not(:last-child),
    td:not(:last-child) {
      border-right: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
    }

    /* Apply a border to the bottom of all but the last row */
    >thead>tr:not(:last-child)>th,
    >thead>tr:not(:last-child)>td,
    >tbody>tr:not(:last-child)>th,
    >tbody>tr:not(:last-child)>td,
    >tfoot>tr:not(:last-child)>th,
    >tfoot>tr:not(:last-child)>td,
    >tr:not(:last-child)>td,
    >tr:not(:last-child)>th,
    >thead:not(:last-child),
    >tbody:not(:last-child),
    >tfoot:not(:last-child) {
      border-bottom: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
    }

  }

  .table-#{$status}-no-border {
    table-layout: auto;
    width: 100%;

    td {
      color: nb-theme(text-hint-color);
      padding: 0.5rem 1rem;
    }

    th {
      background-color: nb-theme(card-header-#{$status}-background-color);
      color: nb-theme(card-header-#{$status}-text-color);
      padding: 0.6rem 1rem;
    }

    tr:hover {
      background-color: nb-theme(card-divider-color);
    }

    tr.selected,  tr.selected td{
      @extend %extend-selected;
    }
  }
}

.table-no-hover tr:hover {
  background-color: unset;
}

.table-no-border {
  border: none;
}

// Para que el menú contextual se alinee a la izquierda
.menu-title {
  text-align: left;
  padding-left: 0.5rem;
}



.box-neutral {
  border: nb-theme(card-border-width) nb-theme(card-border-style) nb-theme(color-basic-600);
  border-radius: nb-theme(card-border-radius);
  background-color: nb-theme(color-basic-600);
  color: nb-theme(color-basic-100);
  padding: 0 0.3rem 0 0.3rem;
  white-space: nowrap;
}

.box-positive {
  border: nb-theme(card-border-width) nb-theme(card-border-style) nb-theme(color-success-500);
  border-radius: nb-theme(card-border-radius);
  background-color: nb-theme(color-success-500);
  color: nb-theme(color-basic-100);
  padding: 0 0.3rem 0 0.3rem;
  white-space: nowrap;
}
.box-negative {
  border: nb-theme(card-border-width) nb-theme(card-border-style) nb-theme(color-danger-500);
  border-radius: nb-theme(card-border-radius);
  background-color: nb-theme(color-danger-500);
  color: nb-theme(color-basic-100);
  padding: 0 0.3rem 0 0.3rem;
  white-space: nowrap;
}


.text-disabled {
  color: nb-theme(text-hint-color);
}

.text-neutral {
  color: nb-theme(color-basic-600);
  white-space: nowrap;
}

.text-positive {
  color: nb-theme(color-success-500);
  white-space: nowrap;
}
.text-negative {
  color: nb-theme(color-danger-500);
  white-space: nowrap;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.card-section {
  padding: nb-theme(card-padding);
  border-bottom: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
}

%extend-card-list {
  nb-list-item:first-child {
    border-top: unset;
  }

  > :hover {
    background-color: nb-theme(card-divider-color);
  }

  > .active:not(:hover) {
    background-color: nb-theme(color-basic-transparent-100);
  }
}

.card-list {
  @extend %extend-card-list;

  nb-list-item {
    padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  }
}

.card-list-tall {
  @extend %extend-card-list;
}


nb-list-item.selected {
  @extend %extend-selected;
  @extend %extend-selected-border;
}

.divider-vertical {
  border-left: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
  width: nb-theme(card-divider-width);
  margin: 0.5rem;
}

.border-left {
  border-left: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
}
.border-right {
  border-right: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
}
.border-top {
  border-top: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
}
.border-bottom {
  border-bottom: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
}



.button-header-ghost {
  padding: 0.2rem !important;
}

.border-top-danger {
  border-top-style: nb-theme(card-border-style);
  border-top-width: nb-theme(card-border-radius);
  border-top-color: nb-theme(card-header-danger-background-color);
}
.border-top-primary {
  border-top-style: nb-theme(card-border-style);
  border-top-width: nb-theme(card-border-radius);
  border-top-color: nb-theme(card-header-primary-background-color);
}
.border-top-warning {
  border-top-style: nb-theme(card-border-style);
  border-top-width: nb-theme(card-border-radius);
  border-top-color: nb-theme(card-header-warning-background-color);
}
.border-top-basic {
  border-top-style: nb-theme(card-border-style);
  border-top-width: nb-theme(card-border-radius);
  border-top-color: nb-theme(card-header-basic-background-color);
}
.border-top-info {
  border-top-style: nb-theme(card-border-style);
  border-top-width: nb-theme(card-border-radius);
  border-top-color: nb-theme(card-header-info-background-color);
}

.display-none {
  display: none !important;
}

.scrollable-container {
  scroll-behavior: smooth;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.position-relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer !important;

  .disabled {
    cursor: none !important;
    pointer-events: none !important;
  }

}

.cursor-disable-events {
  cursor: none !important;
  pointer-events: none !important;
}

.font-weight-bold {
  font-weight: 700!important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-vertical {
  display: flex;
  align-items: center;
}
.no-wrap{
  white-space: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
  row-gap: 0.5rem;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-space-between-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-space-between-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-vertical-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.flex-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.flex-strech {
  align-items: stretch;
}

.link {
  color: nb-theme(color-primary-default);
}

.form-field {
  margin: 0.5rem 0 0.5rem 0;
}
.form-label {
  color: nb-theme(text-hint-color);
  font-size: 0.8rem;
}

.form-help {
  color: nb-theme(text-disabled-color);
  font-size: 0.8rem;
}
.form-invalid {
  // background-color: nb-theme(color-warning-default) !important;
  border: nb-theme(card-border-width) nb-theme(card-border-style) nb-theme(color-danger-default) !important;
  // border-radius: nb-theme(card-border-radius) !important;
}

.no-border {
  border: none;
}

.no-hover {
  pointer-events: none;
}

.img-border {
  margin: 0.5rem;
  background-color: nb-theme(card-background-color);
  border: nb-theme(card-divider-width) nb-theme(card-divider-style) nb-theme(card-divider-color);
  border-radius: nb-theme(card-border-radius);
}

.content-with-rigth-sidebar {
  width: calc(100% - #{nb-theme(sidebar-width)})
}

.slider-thumb-up {
  .mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
  }
  .mat-slider-thumb {
    transform: scale(0) !important;
  }
  .mat-slider-thumb-label-text {
    opacity: 1 !important;
  }
}

.text-main-header {
  padding: 1rem 0;
  text-align: center;
  font-size: 3.5rem;
  line-height: 4rem;
  font-weight: 900;
}
.text-main-subheader {
  padding: 1rem 0;
  text-align: center;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 900;
}

.text-main-footer {
  padding: 0.5rem 0;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 900;
}

.text-gradient-warm {
  background-color: transparent;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(139deg,#8f5cff 30%,#f53d30 86%,#e8a006);
}

.text-gradien-purple {
  background-color: transparent;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg,#ff9fe1,#9d44fd 50%,#8727ff);
}

.text-gradient-blue {
  background-color: transparent;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(76deg,#21ace7,#1e89ef 49%,#8f5cff);
}

.text-gradient-green {
  background-color: transparent;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(76deg,#b3f3dd,#00bc60 49%,#ffd580);
}

// hide breakpoint (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
@include media-breakpoint-down(sm) {
  .hide-sm {
    display: none !important;
  }

  .no-border-sm {
    border: none !important;
  }
  .text-main-header {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .text-main-subheader{
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .text-main-footer{
    font-size: 0.8rem;
    line-height: 1rem;
  }

}
@include media-breakpoint-down(md) {
  .hide-md {
    display: none !important;
  }
  .no-border-md {
    border: none !important;
  }
  .text-main-header {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .text-main-subheader{
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .text-main-footer{
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@include media-breakpoint-down(lg) {
  .hide-lg {
    display: none !important;
  }
  .no-border-lg {
    border: none !important;
  }
}
@include media-breakpoint-down(xl) {
  .hide-xl {
    display: none !important;
  }
  .no-border-xl {
    border: none !important;
  }
}

.scroll {
  @extend .scrollable-container;
}
.scroll-y {
  @extend .scrollable-container;
  overflow-x: hidden !important;
}
.scroll-x {
  @extend .scrollable-container;
  overflow-y: hidden !important;
}


.thumbnail {
  padding: 1rem;
  max-width: 300px;
  min-width: 200px;
}

.subheader{
  font-size: 0.8rem;
  color: nb-theme(text-hint-color);
}

.tag {
  background-color: nb-theme(tag-filled-basic-background-color);
  border-color: nb-theme(tag-filled-basic-border-color);
  color: nb-theme(tag-filled-basic-text-color);
  font-size: nb-theme(tag-small-text-font-size);
  font-weight: nb-theme(tag-small-text-font-weight);
  line-height: nb-theme(tag-small-text-line-height);
  padding: nb-theme(tag-small-padding);
  border-style: nb-theme(tag-border-style);
  border-width: nb-theme(tag-border-width);
  border-radius: nb-theme(tag-border-radius);
  display: inline-flex;
  align-items: center;
  text-transform: nb-theme(tag-text-transform);
}

.footer-wrapper {
  border: unset !important;
  border-top: nb-theme(card-border-width) nb-theme(card-border-style) nb-theme(card-border-color) !important;
}

.shadow-hover:hover{
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 5px 5px nb-theme(text-hint-color);
}

.unread {
  height: 1rem;
  width: 1rem;
  background-color: nb-theme(color-warning-default);
  border-radius: 50%;
  display: inline-block;
}
