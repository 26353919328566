.title_style_1{
  font-size: $clubintel-size-large !important;
  font-weight: $clubintel-weight-bold;
  color: $clubintel-primary-color;
}

.title_style_2{
  font-size: $clubintel-size-small !important;
  font-weight: $clubintel-weight-bold;
}

.text-center {
  text-align: center;
}

.width100{
  width: 100%;
}
