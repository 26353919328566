
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '../../../../node_modules/@angular/material/index' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50 : #edf2fd,
  100 : #d1dffa,
  200 : #b2caf6,
  300 : #93b5f2,
  400 : #7ca5f0,
  500 : #6595ed,
  600 : #5d8deb,
  700 : #5382e8,
  800 : #4978e5,
  900 : #3767e0,
  A100 : #ffffff,
  A200 : #f6f9ff,
  A400 : #c3d3ff,
  A700 : #aac0ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-warning: (
  50 : #fff5e0,
  100 : #ffe6b3,
  200 : #ffd580,
  300 : #ffc44d,
  400 : #ffb726,
  500 : #ffaa00,
  600 : #ffa300,
  700 : #ff9900,
  800 : #ff9000,
  900 : #ff7f00,
  A100 : #ffffff,
  A200 : #fff8f2,
  A400 : #ffdcbf,
  A700 : #ffcda6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$md-danger: (
  50 : #ffe8ee,
  100 : #ffc5d4,
  200 : #ff9eb8,
  300 : #ff779c,
  400 : #ff5a86,
  500 : #ff3d71,
  600 : #ff3769,
  700 : #ff2f5e,
  800 : #ff2754,
  900 : #ff1a42,
  A100 : #ffffff,
  A200 : #fffefe,
  A400 : #ffcbd3,
  A700 : #ffb2bd,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$md-success: (
  50 : #e0faf2,
  100 : #b3f3dd,
  200 : #80ebc7,
  300 : #4de2b1,
  400 : #26dca0,
  500 : #00d68f,
  600 : #00d187,
  700 : #00cc7c,
  800 : #00c672,
  900 : #00bc60,
  A100 : #e5fff0,
  A200 : #b2ffd4,
  A400 : #7fffb8,
  A700 : #65ffaa,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$md-dark: (
  50 : #e4e6e9,
  100 : #bdbfc7,
  200 : #9195a2,
  300 : #646b7d,
  400 : #434b61,
  500 : #222b45,
  600 : #1e263e,
  700 : #192036,
  800 : #141a2e,
  900 : #0c101f,
  A100 : #5f81ff,
  A200 : #2c59ff,
  A400 : #0035f8,
  A700 : #0030df,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$md-basic: (
  50 : #f8f9fb,
  100 : #eef0f6,
  200 : #e2e7f0,
  300 : #d6dde9,
  400 : #ced5e5,
  500 : #c5cee0,
  600 : #bfc9dc,
  700 : #b8c2d8,
  800 : #b0bcd3,
  900 : #a3b0cb,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #f5f8ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$clubintel-angular-primary: mat.define-palette($md-primary, 500, 300, 900);
$clubintel-angular-accent: mat.define-palette($md-basic, 500, 300, 900);

// The warn palette is optional (defaults to red).
$clubintel-angular-warn: mat.define-palette($md-warning, 500, 300, 900);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$clubintel-angular-theme: mat.define-light-theme((
  color: (
    primary: $clubintel-angular-primary,
    accent: $clubintel-angular-accent,
    warn: $clubintel-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($clubintel-angular-theme);


